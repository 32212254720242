import { render, staticRenderFns } from "./MinersListPopRT.vue?vue&type=template&id=3859b58f&scoped=true&"
import script from "./MinersListPopRT.vue?vue&type=script&lang=js&"
export * from "./MinersListPopRT.vue?vue&type=script&lang=js&"
import style0 from "./MinersListPopRT.vue?vue&type=style&index=0&id=3859b58f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3859b58f",
  null
  
)

export default component.exports