var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simple-table"},[_c('table',{ref:"table",staticClass:"table",style:(_vm.tableStyle)},[_c('thead',{staticClass:"tab-header"},_vm._l((_vm.columns),function(col,i){return _c('td',{key:i,staticClass:"header-cell",class:{'hidden-cell': col.hidden},style:([
                {width: col.width ? (col.width + 'px') : (_vm.defaultCellWidth + 'px')},
                {minWidth: col.minWidth ? (col.minWidth + 'px') : undefined},
                {maxWidth: col.maxWidth ? (col.maxWidth + 'px') : undefined} ])},[_vm._v(" "+_vm._s(col.label || "")+" ")])}),0),(!_vm.hasData)?_c('div',{ref:"emptyBlock",staticClass:"empty-block"},[_c('span',{staticClass:"empty-text"},[_vm._v(" Loading ")])]):_vm._e(),(_vm.hasData || _vm.keyFieldName)?_c('tbody',{staticClass:"tab-body"},_vm._l((_vm.data),function(row){return _c('tr',{key:row[_vm.keyFieldName],staticClass:"data-row",on:{"click":function($event){_vm.rowClick && _vm.rowClick(row)}}},_vm._l((_vm.columns),function(col,i){return _c('td',{key:i,staticClass:"data-cell sm-txt",class:{'hidden-cell': col.hidden},style:([
                    {width: col.width ? (col.width + 'px') : (_vm.defaultCellWidth + 'px')},
                    {minWidth: col.minWidth ? (col.minWidth + 'px') : undefined},
                    {maxWidth: col.maxWidth ? (col.maxWidth + 'px') : undefined},
                    col.cellStyle && col.cellStyle(row)])},[(col.isAction && (col.show && col.show(row)))?_vm._t("action-slot",null,{"data":row}):[_vm._v(" "+_vm._s((col.template && col.template(row)) || row[col.field])+" ")]],2)}),0)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }